import React from "react"

import Layout from "../components/layout-index"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1 style={{
      fontFamily: 'sandbrush',
      fontSize: '8rem',
      fontWeight: '200',
      margin: 0,
      lineHeight: '0.75',
      color: '#323232'
    }}>Not Found</h1>
    <p>Sorry, there's nothing here ...</p>
  </Layout>
)

export default NotFoundPage
